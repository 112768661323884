@font-face {
    font-family: Castelar;
    src: url(fonts/Castelar.ttf);
  }
@font-face {
    font-family: Rovas;
    src: url(fonts/Rvs.ttf);
  }

.autocomplete-input .MuiOutlinedInput-notchedOutline {
    border-top: none;
    border-right: none;
    border-left: none;
}

.autocomplete-input:hover .MuiOutlinedInput-notchedOutline {
    border-top: none;
    border-right: none;
    border-left: none;
}

.autocomplete-input.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-top: none;
    border-right: none;
    border-left: none;
}

.polygon-hu-label {
    font-size: 12px;
    font-weight: 500;
    color: #b90c0c;
    text-align: center;
    padding: 2px;    
}

.polygon-ro-label {
    font-size: 10px;
    font-style: italic;
    color: #000000;
    text-align: center;
    padding: 2px;    
}

.polygon-sic-label {
    font-size: 20px;
    font-weight: 500;
    color: #012837;
    text-align: center;
    padding: 2px; 
    font-family: "Rovas";   
}

.polygon-label {
    padding: 1px;
    margin: 0px;
}

.szek-lat-label {
    font-size: 12px;
    font-style: italic;
    color: #000000;
    text-align: center;
    padding: 2px; 
    
}

.szek-label {
    background-color: gold;  
}

.megye-label {
    background-color: #b90c0c;
    color: white;
}
